<ng-container *ngIf="placements$ | async as placements">

  <div [ngClass]="{'card-wrapper': portalType === 'portal'}">
    <nz-card [ngClass]="{'symmetric': portalType === 'portal'}">
      <app-filters [filters]="placementsFilters$ | async"
                   [authPermissions]="authPermissions"
                   [isDataLoading]="placements.isLoading"
                   [buttons]="placementsTableButtons"
                   (filtersChanged)="onFiltersChange($event)"
                   (filterSearched)="onFilterSearch($event)"
                   (buttonClicked)="onButtonClick($event)">
      </app-filters>
    </nz-card>
  </div>

  <div [ngClass]="{'card-wrapper': portalType === 'portal'}">
    <nz-card [ngClass]="{'symmetric': portalType === 'portal'}">
      <nz-alert [nzMessage]="tagConfigurationAlert" nzShowIcon nzType="info" style="margin-bottom: 1.5em;">
        <ng-template #tagConfigurationAlert>
          For tag configuration, visit our 
          <span (click)="onTagHelpCenterClick()" class="underlined-link-button">Help Center</span>. 
        </ng-template>
      </nz-alert>
      <app-table [authPermissions]="authPermissions"
                 [isTableLoading]="placements.isLoading"
                 [table]="placementsTable"
                 [tableColumns]="placementsTableColumns"
                 [tableData]="placements.data"
                 [totalRows]="placements.totalElements"
                 [isPaginated]="placements.isPaginated"
                 [isExternal]="portalType === 'portal'"
                 [hasSizeChanger]="true"
                 [hasFooter]="true"
                 [idProp]="'placementId'"
                 (tableSortChanged)="onTableSortChange($event)"
                 (tablePageChanged)="onTablePageChange($event)"
                 (placementsAction)="onRowAction($event)">
      </app-table>
    </nz-card>
  </div>
</ng-container>

<nz-modal [(nzVisible)]="placementBulkUploadModalShow" nzTitle="Placement bulk create"
          (nzOnCancel)="closePlacementBulkUploadModal()" (nzOnOk)="closePlacementBulkUploadModal()" [nzFooter]="modalFooter">
  <ng-container *nzModalContent>

    <p>You can download the CSV template <a href="/resources/20241211124806/assets/downloads/placement_bulk_upload_template.csv"
                                            download="placement_bulk_upload_template.csv">here</a>.</p>

    <nz-alert *ngIf="placementBulkUploadLastUploadId" nzType="success"
              nzMessage="File uploaded successfully. Upload ID: {{placementBulkUploadLastUploadId}}">
    </nz-alert>

    <br>

    <nz-upload
      nzType="drag"
      [nzMultiple]="false"
      [nzLimit]="1"
      [nzAction]="placementBulkUrl"
      [nzFileType]="'text/plain,application/vnd.ms-excel,text/x-csv,application/csv,application/x-csv,text/csv,text/comma-separated-values,text/x-comma-separated-values,text/tab-separated-values'"
      (nzChange)="onBulkPlacementUploadFinished($event)"
    >
      <p class="ant-upload-drag-icon">
        <em nz-icon nzType="inbox"></em>
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">
        Accepts only predefined CSV file.
      </p>
    </nz-upload>

    <nz-divider nzText="Check upload status"></nz-divider>

    <ng-template #prefixSearchTemplate><em nz-icon nzType="search"></em></ng-template>
    <nz-input-group nzSearch [nzPrefix]="prefixSearchTemplate" [nzAddOnAfter]="suffixSearchButton" class="upload-search">
      <input type="text" nz-input [(ngModel)]="placementBulkUploadSearch" class="upload-search-input"
             placeholder="Upload ID" [ngModelOptions]="{standalone: true}">
    </nz-input-group>
    <ng-template #suffixSearchButton>
      <button nz-button type="button" nzType="primary" nzSearch (click)="getBulkUploadStatus()">Check</button>
    </ng-template>
    <br><br>

    <nz-alert *ngIf="placementBulkUploadStatus"
              nzType="{{placementBulkUploadErrors.length ? 'warning' : 'success'}}"
              nzMessage="Status:  {{placementBulkUploadStatus | titlecase}} {{placementBulkUploadErrors.length ? ' with errors' : ''}}">
    </nz-alert>

    <ng-container *ngIf="placementBulkUploadErrors.length">
      <h4 class="margin-top-1">Errors:</h4>
      <cdk-virtual-scroll-viewport itemSize="38" class="upload-messages">
        <nz-list nzSize="small" class="padding-left-small">
          <nz-list-item *cdkVirtualFor="let error of placementBulkUploadErrors">
            {{getErrorText(error)}}
          </nz-list-item>
        </nz-list>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </ng-container>

  <ng-template #modalFooter>
    <button nz-button class="secondary-button" (click)="closePlacementBulkUploadModal()">Close</button>
  </ng-template>
</nz-modal>
