import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({providedIn: 'root'})

export class SharedSupplyPlacementService {

  constructor(
    private http: HttpClient,
  ) {
  }

  // GEO FLOORS STUFF
  getCountries(query: any = {}) {
    return this.http.post(`${environment.javaApiUrl}/location/country/get`, query);
  }

  getDeviceTypes() {
    return this.http.get(`${environment.javaApiUrl}/optionsList/?enumKeys=DeviceType`);
  }
}
